import { MetaIdJs } from "./metaidjs"

declare global {
  interface Window {
    MetaIdJs?: Function; 
    handleNotEnoughMoney?: Function; 
  }
}

window.MetaIdJs = MetaIdJs

export default MetaIdJs