import Popup from "./popup";
import PostmessageClient from "./postmessage-client";

declare global {
  interface Window {
    mainFrameMessage: PostmessageClient;
  }
}

interface ObjTypes<T> {
  [key: string]: T;
  [key: number]: T;
}
interface BaseParamsType {
  accessToken: string;
  callback?: Function;
  onCancel?: Function;
}
interface OauthSettingsTypes {
  clientId: string;
  redirectUri: string;
  clientSecret?: string;
  scope?: string;
  responseType?: string;
}
interface ResponseTypes {
  code: number;
  status: string;
  handlerId?: string;
  data: ResponseDataTypes;
}
interface ResponseDataTypes {
  message: string;
  [key: string]: any;
}
interface ConstructorOptionsTypes {
  baseUri?: string;
  oauthSettings: OauthSettingsTypes;
  onLoaded?: Function;
  onError?: Function;
}
export interface ProtocolOptions {
  nodeName: string;
  version?: string;
  data?: string;
  dataType?: string;
  encoding?: string;
  parentTxId?: string;
  encrypt?: number | string;
  metaIdTag?: string;
  keyPath?: string;
  parentAddress?: string;
  needConfirm?: boolean;
  checkOnly?: boolean;
  brfcId?: string;
  path: string;
  nodeKey?: string;
  payCurrency?: string;
  payTo?: SendToTypes[];
  autoRename?: boolean;
}
interface SendToTypes {
  address: string;
  amount: number;
}
interface ProtocolParamsTypes extends ProtocolOptions, BaseParamsType {
  accessToken: string;
  handlerId?: string;
}

const generateRandomId = (): string => {
  return Math.floor(Math.random() * 100000000000000000).toString();
};
const hasClass = (el: HTMLElement, cls: string) => {
  return el.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
};
const addClass = (el: HTMLElement, cls: string) => {
  if (!hasClass(el, cls)) el.className += " " + cls;
};
export class MetaIdJs {
  postMessage = new PostmessageClient(window);
  oauthSettings: OauthSettingsTypes;
  mainFrameEl: HTMLIFrameElement | null = null;
  SHOWMONEY_URL: string;
  accessToken = "";
  isInjectMainFrame = false;
  isLoaded = false;
  _handlers: ObjTypes<any> = {};
  onLoaded: Function | undefined;
  onError: Function = (res: ResponseTypes) => {
    Popup.error({
      message: res.data.message,
    });
  };

  constructor(options: ConstructorOptionsTypes) {
    this.SHOWMONEY_URL = options.baseUri || "https://www.showmoney.app";
    this.onLoaded = options.onLoaded;
    if (typeof options.onError === "function") {
      this.onError = options.onError;
    }
    this.oauthSettings = {
      ...options.oauthSettings,
      clientSecret: "",
      scope: "app",
      responseType: "code",
    };
    this.init();
  }

  /**
   * injectMainFrame  注入主框架
   */
  public injectMainFrame() {
    const mainFrame = document.createElement("iframe");
    const mainFrameWrapper = document.createElement("div");
    mainFrame.setAttribute("id", "showmoney-main-frame");
    mainFrame.setAttribute("src", this.SHOWMONEY_URL + "/iframe");
    mainFrameWrapper.setAttribute("id", "mainframewrapper");
    mainFrameWrapper.setAttribute("style", "display: none;");
    mainFrameWrapper.appendChild(mainFrame);
    document.body.appendChild(mainFrameWrapper);
    // 报告 main-frame 载入完成
    mainFrame.onload = () => {
      if (mainFrame.contentWindow) {
        this.mainFrameEl = mainFrame;
        // 无法把postmessage实例保留，会报跨域错误
        window.mainFrameMessage = new PostmessageClient(mainFrame.contentWindow);
        window.mainFrameMessage.send("send-options", this.oauthSettings);
        // window.mainFrameMessage = mainFrameMessage
        this.isInjectMainFrame = true;
        // console.log('mainFrame loaded')
      }
    };
  }
  private initHandle() {
    const functionObj = [
      "swapreqswapargs",
      "estimateSwapToken2Amount",
      "estimateSwapToken1Amount",
      "isSupportedFt",
      "swapft",
      "getBalance",
      "nftBuy",
      "nftCancel",
      "nftSell",
      "genesisNFT",
      "issueNFT",
      "transferFT",
      "sendTxAuto",
      "preFetchSignRaw",
      "createBrfcProtocolNode",
      "sendRedEnvelope",
      "getRedEnvelope",
      "resumeTransaction",
      "paytoAddress"
    ];
    for (const item of functionObj) {
      (this as any)[item] = function (params: {
        accessToken: string;
        callback?: Function;
        data: string;
      }) {
        const defParams: ObjTypes<any> = params;
        if (params.callback) {
          const handlerId = generateRandomId();
          this._handlers[handlerId] = {};
          this._handlers[handlerId].callback = params.callback;
          defParams.handlerId = handlerId;
        }
        delete defParams.callback;
        window.mainFrameMessage.send(item, defParams);
      };
    }
  }
  /**
   * getUserInfo
   */
  public getUserInfo(params: { accessToken: string; callback?: Function }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("get-user-info", defParams);
  }
  /**
   * signMessage
   */
  public signMessage(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("sign-messgae", defParams);
  }
  /**
   * eciesEncryptData
   */
  public eciesEncryptData(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("ecies-encrypt-data", defParams);
  }
  public eciesDecryptData(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("ecies-decrypt-data", defParams);
  }
  /**
   * ecdhEncryptData
   */
  public ecdhEncryptData(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("ecdh-encrypt-data", defParams);
  }
  public ecdhDecryptData(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("ecdh-decrypt-data", defParams);
  }
  public getFTList(params: { accessToken: string; callback?: Function; data?: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("get-ftlist", defParams);
  }
  // 版本兼容
  public addProtocolNode(params: ProtocolParamsTypes) {
    this.sendMetaDataTx(params);
  }
  /**
   * createProtocolNode
   */
  public sendMetaDataTx(params: ProtocolParamsTypes) {
    if (!params.needConfirm === false && !params.checkOnly) {
      this.showLoadingPopup();
    }
    if (params.callback || params.onCancel) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      if (params.callback) {
        this._handlers[handlerId]["callback"] = params.callback;
      }
      if (params.onCancel) {
        this._handlers[handlerId]["onCancel"] = params.onCancel;
      }
      params = {
        ...params,
        handlerId: handlerId,
      };
      delete params.callback;
      delete params.onCancel;
    }
    if (this.isInjectMainFrame) {
      window.mainFrameMessage.send("create-node", params);
      // console.log(this._handlers)
    } else {
      throw new Error("showmoney frame 未加载");
    }
  }

  public payToAddress(params: { accessToken: string; callback?: Function; data: string }) {
    const defParams: ObjTypes<any> = params;
    if (params.callback) {
      const handlerId = generateRandomId();
      this._handlers[handlerId] = {};
      this._handlers[handlerId].callback = params.callback;
      defParams.handlerId = handlerId;
    }
    delete defParams.callback;
    window.mainFrameMessage.send("pay-to-address", defParams);
  }
  private handleCreateNodeSuccess = (res: ObjTypes<any>) => {
    Popup.close();
    this.mainFrameEl?.parentElement?.setAttribute("style", "display: none;");
    const payload = res.payload;
    const callback = this._handlers[payload.handlerId].callback;
    if (callback) {
      callback(payload);
    }
  };
  private showLoadingPopup() {
    const popupEl = document.getElementById("showmoney-popup");
    if (!popupEl) return;
    Popup.info({
      message: "Processing data...",
      showClose: false,
    });
    addClass(popupEl, "loading");
  }
  private handleCreateNodeError = (res: ObjTypes<any>) => {
    const payload = res.payload;
    Popup.close();
    Popup.error({
      message: payload.data && payload.data.message ? payload.data.message : res,
    });
  };
  private handleConfirmCreateNode = (res: ObjTypes<any>) => {
    // console.log("confirm", res);
    Popup.close();
    this.mainFrameEl?.parentElement?.removeAttribute("style");
  };
  private handleCloseCreateNode = (res: ObjTypes<any>) => {
    Popup.close();
    this.mainFrameEl?.parentElement?.setAttribute("style", "display: none;");
    const payload = res.payload;
    const callback = this._handlers[payload.handlerId].onCancel;
    if (callback) {
      delete payload.handlerId;
      callback(payload);
    }
  };
  private handleErrorNotLoggedIn(resolve: ObjTypes<any>) {
    const message = resolve.payload;
    Popup.close();
    Popup.info(message.popup);
  }
  private handleCallback = (res: ObjTypes<any>) => {
    const payload = res.payload;
    const callback = this._handlers[payload.handlerId].callback;
    if (callback) {
      delete payload.handlerId;
      callback(payload);
    }
  };
  private handleSdkLoaded = () => {
    this.isLoaded = true;
    if (typeof this.onLoaded === "function") {
      this.onLoaded();
    }
  };
  private handleCommonError = (res: ObjTypes<any>) => {
    console.log("error", res);
    const payload = res.payload;
    Popup.close();
    if (payload.code === 202) {
      Popup.confirm({
        message: "User authentication expired.",
        showClose: false,
        buttonText: "Cancel",
        buttonText2: "Login",
        buttonUrl2: `${this.SHOWMONEY_URL}/userLogin?response_type=code&client_id=${this.oauthSettings.clientId}&redirect_uri=${this.oauthSettings.redirectUri}&scope=app&from=${this.oauthSettings.redirectUri}`,
      });
    } else {
      this.onError(payload);
    }
  };
  private handleLoading = () => {
    this.showLoadingPopup();
  };
  private handleNotEnoughMoney = (res: ObjTypes<any>) => {
    const payload = res.payload;
    const message = payload.data.message;
    Popup.close();
    Popup.confirm({
      message: message ? message : "Not enough money",
      showClose: false,
      buttonText: "Cancel",
      buttonText2: "Top up BSV",
      buttonUrl2: this.SHOWMONEY_URL,
      buttonAction: () => {
        Popup.close();
        if ((window as any).handleNotEnoughMoney) {
          (window as any).handleNotEnoughMoney(payload);
        }
        // const callback = this._handlers[payload.handlerId].callback
        // if (callback) {
        //   callback(payload)
        // }
      },
    });
  };
  private init() {
    this.injectMainFrame();

    // 监听信息
    this.postMessage.start();
    this.initHandle();
    this.postMessage.subscribe("sdk-loaded", this.handleSdkLoaded);
    this.postMessage.subscribe("error.not-logged-in", this.handleErrorNotLoggedIn);
    this.postMessage.subscribe("loading", this.handleLoading);
    this.postMessage.subscribe("success.create-node", this.handleCreateNodeSuccess);
    this.postMessage.subscribe("confirm.create-node", this.handleConfirmCreateNode);
    this.postMessage.subscribe("close.create-node", this.handleCloseCreateNode);
    this.postMessage.subscribe("error.create-node", this.handleCreateNodeError);
    this.postMessage.subscribe("error.not-enough-money", this.handleNotEnoughMoney);
    this.postMessage.subscribe("receive-callback", this.handleCallback);
    this.postMessage.subscribe("error.common", this.handleCommonError);
  }
}
