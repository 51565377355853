// Import here Polyfills if needed. Recommended core-js (npm i -D core-js)
// import "core-js/fn/array.find"
// ...

const generateRandomId = ():string => {
  return (Math.floor(Math.random() * 100000000000000000)).toString()
}

interface ObjTypes<T> {
  [key: string]: T;
  [key: number]: T;
}

export default class PostmessageClient {
  handlers: ObjTypes<Function>
  targetWindow: Window | null
  _pendingMessages: []
  _deliverMessages: boolean
  _replayQueue: object

  constructor (window: Window | null ) {
    this.handlers = {}
    this.targetWindow = window
    this._pendingMessages = []
    this._deliverMessages = false
    this._replayQueue = {}
  }

  start() {
    window.addEventListener('message', this._onMessageReceived, false)
  }
  finalize() {
    window.removeEventListener('message', this._onMessageReceived, false)
  }
  subscribe(topic: string, handler: Function) {
    this.handlers[topic] = handler
  }
  send(topic: string, payload: any, metadata = {}) {
    if (!this.targetWindow) {
      return console.log('请指定目标窗口')
    }
    const messageId = generateRandomId()
    const message = {
      v1: {
        topic,
        payload,
        messageId,
        ...metadata
      }
    }
    this.targetWindow.postMessage(message, '*')
    return message
  }
  _onMessageReceived = async (event: ObjTypes<any>) => {
    if (!event.data || !event.data.v1) {
      return
    }
    const message = event.data.v1
    const handler = this.handlers[message.topic]
    if (handler) {
      try {
        const response = await handler(message)
        if (message.reply) {
          this.send(`${message.topic}:reply`, response, { repliesTo: message.messageId })
        }
      } catch (e) {
        console.error(e)
        throw e
      }
    }
  }
}