/*
 * @Author: ohosanna 
 * @Date: 2020-03-18 14:45:22 
 * @Last Modified by: ohosanna
 * @Last Modified time: 2020-07-08 16:48:53
 */

import './styles/popup.css'

interface PopupOptionsTypes {
  title?: string;
  message: string;
  className?: string;
  showClose?: boolean;
  buttonText?: string;
  buttonText2?: string;
  buttonUrl?: string;
  buttonUrl2?: string;
  buttonAction?: Function;
  buttonAction2?: Function;
  useCurrentWindow?:boolean;
}

class Popup {
  popupWrapper: HTMLElement
  constructor() {
    const popupEl = document.createElement('div')
    popupEl.setAttribute('id', 'showmoney-popup')
    document.body.appendChild(popupEl)
    this.popupWrapper = popupEl
  }
  info(options: PopupOptionsTypes) {
    this.show('info', options)
  }
  error(options: PopupOptionsTypes) {
    this.show('error', options)
  }
  loading() {
    console.log(this.popupWrapper)
    this.show('info', {message: 'test'})
  }
  confirm(options: PopupOptionsTypes) {
    if (!options.buttonAction) {
      options.buttonAction = () => {
        this.close()
      }
    }
    this.show('confirm', options)
  }
  show(type: string = 'info', options: PopupOptionsTypes) {
    const popupDom = this.generatePopupContent(options)
    this.popupWrapper.appendChild(popupDom)
    this.popupWrapper.className = type + '-popup' + ' active'
  }
  close() {
    this.popupWrapper.className = ''
    this.popupWrapper.innerHTML = ''
  }
  generatePopupContent(options: PopupOptionsTypes) {
    const box = document.createElement('div')
    box.className = 'sm-popup-box ' + (options.className ? options.className : '')

    // progress
    const progress = document.createElement('div')
    progress.className = 'sm-linear-progress'
    const bar1 = document.createElement('div')
    bar1.className = 'bar bar1'
    const bar2 = document.createElement('div')
    bar2.className = 'bar bar2'
    progress.appendChild(bar1)
    progress.appendChild(bar2)
    box.appendChild(progress)

    // header
    const popupHeader = document.createElement('header')
    popupHeader.className = 'sm-popup-header'
    const popupTitle = document.createElement('h2')
    popupTitle.className = 'sm-popup-title'
    if(options.title) {
      popupTitle.innerHTML = options.title
      popupHeader.appendChild(popupTitle)
    }
    const closeBtn = document.createElement('button')
    closeBtn.className = 'sm-popup-close'
    closeBtn.onclick = () => { this.close() }
    if (options.showClose !== false) {
      popupHeader.appendChild(closeBtn)
    }

    // content
    const popupContent = document.createElement('div')
    popupContent.className = 'sm-popup-content'
    popupContent.innerHTML = options.message

    // footer
    const popupFooter = document.createElement('footer')
    popupFooter.className = 'sm-popup-footer'
    if(options.buttonText) {
      const button = document.createElement('button')
      button.className = 'sm-popup-btn sm-popup-btn1'
      button.innerText = options.buttonText
      if(options.buttonUrl) {
        const url = options.buttonUrl
        button.onclick = () => {
          window.open(url, '_blank')
        }
      }
      if (options.buttonAction) {
        button.onclick = () => {
          if (typeof options.buttonAction === 'function') options.buttonAction()
        }
      }
      popupFooter.appendChild(button)
    }
    if(options.buttonText2) {
      const button2 = document.createElement('button')
      button2.className = 'sm-popup-btn sm-popup-btn2'
      button2.innerText = options.buttonText2
      if (options.buttonUrl2) {
        const url = options.buttonUrl2
        button2.onclick = () => {
          if(options.useCurrentWindow){
            window.open(url)
          }else{
            window.open(url, '_blank')
          }
        }
      }
      if (options.buttonAction2) {
        button2.onclick = () => {
          if (typeof options.buttonAction2 === 'function') options.buttonAction2()
        }
      }
      popupFooter.appendChild(button2)
    }

    box.appendChild(popupHeader)
    box.appendChild(popupContent)
    box.appendChild(popupFooter)
    return box
  }
}

export default new Popup()